class Favourites {

    constructor() {
        this.datastore = null;
    }

    /**
     * Open a connection to the datastore.
     */
    open(callback) {
        // Database version.
        var version = 1;
        var self = this;

        // Open a connection to the datastore.
        var request = indexedDB.open('favourites', version);

        // Handle datastore upgrades.
        request.onupgradeneeded = function (e) {
            var db = e.target.result;

            e.target.transaction.onerror = this.onerror;

            // Delete the old datastore.
            if (db.objectStoreNames.contains('favourite')) {
                db.deleteObjectStore('favourite');
            }

            // Create a new datastore.
            var store = db.createObjectStore('favourite', {
                keyPath: 'id'
            });
        };

        // Handle successful datastore access.
        request.onsuccess = function (e) {
           // Get a reference to the DB.
            self.datastore = e.target.result;

            // Execute the callback.
            if (typeof callback != 'undefined') {
                callback();
            }
        };

        // Handle errors when opening the datastore.
        request.onerror = this.onerror;
    };

    /**
     * Fetch all of the favourite items in the datastore.
     */
    fetchAll(callback) {
        console.log('fetchall');
        var db = this.datastore;
        var transaction = db.transaction(['favourite'], 'readonly');
        var objStore = transaction.objectStore('favourite');

        var keyRange = IDBKeyRange.lowerBound(0);
        var cursorRequest = objStore.openCursor(keyRange);

        var favourites = [];

        transaction.oncomplete = function (e) {
            // Execute the callback function.
            if (typeof callback != 'undefined') {
                callback(favourites);
            }
        };

        cursorRequest.onsuccess = function (e) {
            var result = e.target.result;

            if (!!result == false) {
                return;
            }

            favourites.push(result.value);

            result.continue();
        };

        cursorRequest.onerror = this.onerror;
    };

    /**
     * Fetch all of the favourite items in the datastore.
     */
    fetchAllAsync() {
        console.log('fetchallasync');
/*        var db = datastore;
        let transaction = db.transaction(['favourite'], 'readonly');
        let objStore = transaction.objectStore('favourite');

        let keyRange = IDBKeyRange.lowerBound(0);
        let cursorRequest = await objStore.openCursor(keyRange);



        var favourites = [];

        await transaction.complete;

        console.log(cursorRequest);

        return cursorRequest;*/

        return new Promise(function(resolve) {
            var db = this.datastore;   
            let transaction = db.transaction(['favourite'], 'readonly');
            var store = transaction.objectStore('favourite');
            store.getAll().onsuccess =  function (event) {
                return resolve(event.target.result);
            }
        });
    };

    /**
     * Fetch of the favourite items in the datastore.
     */
    fetch(id, callback) {
        var db = this.datastore;
        var transaction = db.transaction(['favourite'], 'readonly');
        var objStore = transaction.objectStore('favourite');

        var request = objStore.get(id);

        request.onsuccess = function (e) {
            if (typeof callback != 'undefined') {
                callback(request.result);
            }
        }

        request.onerror = function (e) {
            console.log(e);
        }
    };

    /**
     * Create a new favourite item.
     */
    create(favourite, callback) {
        // Get a reference to the db.
        var db = this.datastore;

        // Initiate a new transaction.
        var transaction = db.transaction(['favourite'], 'readwrite');

        // Get the datastore.
        var objStore = transaction.objectStore('favourite');

        // Create the datastore request.
        var request = objStore.put(favourite);

        // Handle a successful datastore put.
        request.onsuccess = function (e) {
            // Execute the callback function.
            if (typeof callback != 'undefined') {
                callback(favourite);
            }
        };

        // Handle errors.
        request.onerror = this.onerror;
    };

    /**
     * Delete a new favourite item.
     */
    delete(id, callback) {
        var db = this.datastore;
        var transaction = db.transaction(['favourite'], 'readwrite');
        var objStore = transaction.objectStore('favourite');

        var request = objStore.delete(id);

        request.onsuccess = function (e) {
            if (typeof callback != 'undefined') {
                callback();
            }
        }

        request.onerror = function (e) {
            console.log(e);
        }
    };
}

module.exports = Favourites;