window.$ = window.jQuery = require('jquery');
require('foundation-sites');
require('motion-ui');

import { createApp } from 'vue/dist/vue.esm-bundler';
import signit from './../vue/signit.vue';

$(function() {
    createApp(signit).mount('#signit');
});

const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone);

window.onerror = function (message, file, line, col, error) {
    alert("Error occurred: " + error.message);
    return false;
};

(function() {
    if (window.location.pathname != '/home/unsupported' && window.location.pathname != '/user/register' && window.location.pathname != '/tutorial' && window.location.pathname != '/tutorial/install')
    if (typeof(Storage) == "undefined") {
        // Sorry! No Web Storage support..
        window.location = '/home/unsupported';
    } else {
        // Code for localStorage/sessionStorage.
        if (localStorage.industry == null || localStorage.company == null || localStorage.role == null) {
            if (isInStandaloneMode()) {
                window.location = '/user/register';
            } else {
                window.location = '/tutorial/install';
            }
        }
    }
})();

$(function() {
    if ($('#home').length == 1 && false) {
        $('#items').addClass('hide');
        if (navigator.onLine) {
            $.get('/category', function(data) {
                $('#categories').append('<h2 class="text-center">Browse</h2>');
                var catlist = $('<div class="grid-x small-up-2 medium-up-4 grid-margin-x"></h2>').appendTo('#categories');
                $.each(data.categories, function(index, value) {
                    catlist.append('<div class="cell"><button type="button" class="button item-button" data-cat-id="' + index + '">' + value + '</button></div>');
                });
                $('#online').removeClass('hide');
            });
        }

        $('#categories').on('click', 'button', function() {
            var cat = $(this).attr('data-cat-id');
            $.get('/category/items/' + cat, function(data) {
                items = data.items;
                $('#items').html('<h2 class="text-center">Items for ' + data.category + '</h2>');
                var itemlist = $('<div class="grid-x small-up-1 medium-up-4 grid-margin-x"></h2>').appendTo('#items');
                $('#items').append('<p class="text-center"><button class="button back-button secondary"><i class="fa fa-chevron-left"></i> Back</button></p>');
                $.each(data.items, function(id, item) {
                    itemlist.append($('<div class="cell" />').append($('<button class="button item-button">' + item.name + '</button>').data('item', item)));
                });
                $('#categories').addClass('hide');
                $('#items').removeClass('hide');
            });
        });

        $('#search-button').on('click', function() {
            $.post('/search', { search: $('#search').val() }, function(data) {
                searchItems = data.items;
                $('.typeahead__container').addClass('cancel').removeClass('result');
                $('#browse').addClass('hide');
                $('#search-results').html('<h2 class="text-center">Search results</h2>');
                var itemlist = $('<div class="grid-x small-up-1 medium-up-4 grid-margin-x"></h2>').appendTo('#search-results');
                $('#search-results').append('<p class="text-center"><button class="button back-button secondary"><i class="fa fa-chevron-left"></i> Back</button></p>');
                $.each(data.items, function(id, item) {
                    itemlist.append($('<div class="cell" />').append($('<button class="button item-button">' + item.name + '</button>').data('item', item)));
                });
                $('#search-results').removeClass('hide');
            });
        });

        $('#search-results').on('click', '.back-button', function() {
            $('#search-results').addClass('hide');
            $('#browse').removeClass('hide');
        });


        $('#items').on('click', '.back-button', function() {
            $('#items').addClass('hide');
            $('#categories').removeClass('hide');
        });

        $('#search-results, #favourites, #items').on('click', '.item-button', function() {
            var item = $(this).data('item');
            showVideo(item);
        });

        $('#view').on('click', '.play-button', function() {
            var video = $('#video')[0];
            if (video.paused) {
                if (video.ended) {
                    video.currentTime = 0;
                }
                video.play();
            } else {
                video.pause();
            }
        });

        $('#view').on('click', '.back-button', function() {
            var video = $('#video')[0];
            video.pause();
            $('#title').html('Welcome to Signit<sup style="body-text">&trade;</sup>').addClass('hide');
            $('#view').addClass('hide');
            $('#home').removeClass('hide');
        });

        /*$('#search').typeahead({
            template: '{{name}}',
            emptyTemplate: "no result for {{query}}",
            dynamic: true,
            delay: 300,
            filter: false,
            source:  {
                item: {
                    display: 'name',
                    ajax: {
                        url: '/search/typeahead/',
                        data: { search: '{{query}}' },
                        path: 'data.items'
                    }
                }
            },
            callback: {
                onClick: function (node, a, item, event) {
                    showVideo(item);
                }
            }
        });*/

        favourites.open(refreshFavourites);
    }
});

function showVideo(item) {
    $('#home').addClass('hide');
    $('#view').removeClass('hide');
    $('#title').html(item.name).removeClass('hide');
    $('#view').data('item', item);
    checkFavourite();
    $('#video source')[0].src = '/assets/media/video/' + item.video;
    $('#video')[0].load();
    $('#video')[0].play();
    if (navigator.onLine) {
        gtag('event', 'watch_video', {
            'event_category' : 'video',
            'event_label' : 'Watch Video: ' + item.name,
            'value' : item.id
        });
    }
}

function refreshFavourites() {
    favourites.fetchAll(function(items) {
        $('#favourites').html('');
        var itemlist = $('<div class="grid-x small-up-1 medium-up-4 grid-margin-x"></h2>').appendTo('#favourites');

        $(items).each(function(index, item) {
            itemlist.append($('<div class="cell" />').append($('<button data-id="' + item.id + '" class="button item-button">' + item.name + '</button>').data('item', item)));
        });
    });
}

function checkFavourite() {
    var currentItem = $('#view').data('item');
    if ($('#favourites .item-button[data-id=' + currentItem.id + ']').length) {
        $('#fav-action').html('<a href="#" class="button" onclick="removeFavourite()"><i class="fa fa-heart"></i> Remove</a>');
    } else {
        $('#fav-action').html('<a href="#" class="button" onclick="addFavourite()"><i class="fa fa-heart-o"></i> Add</a>');
    }
}

function addFavourite() {
    var currentItem = $('#view').data('item');
    if (navigator.onLine) {
        gtag('event', 'add_favourite', {
            'event_category' : 'video',
            'event_label' : 'Add Favourite: ' + currentItem.name,
            'value' : currentItem.id
        });
    }
    favourites.create(currentItem, function() {
        $('#fav-action').html('<a href="#" class="button" onclick="removeFavourite()"><i class="fa fa-heart"></i> Remove</a>');
        cacheFavourite('/assets/media/video/' + currentItem.video);
        refreshFavourites();
    });
}

function removeFavourite() {
    var currentItem = $('#view').data('item');
    if (navigator.onLine) {
        gtag('event', 'remove_favourite', {
            'event_category' : 'video',
            'event_label' : 'Remove Favourite: ' + currentItem.name,
            'value' : currentItem.id
        });
    }
    favourites.delete(currentItem.id, function() {
        $('#fav-action').html('<a href="#" class="button" onclick="addFavourite()"><i class="fa fa-heart-o"></i> Add</a>');
        uncacheFavourite('/assets/media/video/' + currentItem.video);
        refreshFavourites();
    });
}

function cacheFavourite(url) {
    caches.open('video-cache').then(cache => {
        cache.add(url).then(() => {
            navigator.serviceWorker.controller.postMessage({ 'action': 'updateCache' });
        });
      });
}

function uncacheFavourite(url) {
    caches.open('video-cache').then(cache => {
        cache.delete(url).then(() => {
            navigator.serviceWorker.controller.postMessage({ 'action': 'updateCache' });
        });
      });
}

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.onerror = function (error) {
            console.log('Service worker error', error);
        }
        navigator.serviceWorker.register('/assets/js/serviceworker.js', { scope: '/' })
        .then(swReg => {
            console.log('Service Worker is registered', swReg);
        })
        .catch(err => {
            console.error('Service Worker Error', err);
        });
    });
}