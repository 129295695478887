<template>
	<div>
		<search @select="selectItem"></search>
		<div v-if="favourites != null && favourites.length > 0">
			<h2>Favourites</h2>
			<div class="grid-x small-up-1 medium-up-4 grid-margin-x">
				<div class="cell" v-for="favourite in favourites" :key="favourite.id">
					<button class="button item-button" type="button" @click="selectItem(favourite)">{{ favourite.name }}</button>
				</div>
			</div>
		</div>
		<video-player v-if="selectedItem != null" v-model="selectedItem" @add-favourite="addFavourite(selectedItem)"></video-player>
	</div>
</template>
<script>
	import signItSearch from './../vue/search.vue';
//	import signItFavourites from './../vue/favourites.vue';
	import signItVideo from './../vue/video.vue';
	const Favourites = require('./../js/favourites');

	export default {
		components: {
			search: signItSearch,
//			favourites: signItFavourites,
			videoPlayer: signItVideo
		},
		data() {
			return {
				favouritesDb: new Favourites(),
				favourites: null,
				selectedItem: null
			};
		},
		methods: {
			selectItem(item) {
				this.selectedItem = { id: item.id, name: item.name, video: item.video };
			},
			updateFavourites() {
				var self = this;
				this.favouritesDb.fetchAll(function(items) {
					self.favourites = items;
				});
			},
			addFavourite(item) {
				var self = this;
				this.favouritesDb.create({ id: item.id, name: item.name, video: item.video }, function() {
					self.updateFavourites();
				});
			},
			removeFavourite(item) {
				var self = this;
				this.favouritesDb.delete(item.id, function() {
					self.updateFavourites();
				});
			}			
		},
		mounted() {
			this.favouritesDb.open(this.updateFavourites);
		}
	}
</script>