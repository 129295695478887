<template>
	<div class="player">
		<h3>{{ modelValue.name }}</h3>
		<video ref="video" playsinline preload="auto" @click="play" @pause="playing=false" @play="playing=true" @canplay="play">
			<source :src="modelValue.video" />
		</video>
		<p class="text-center">
			<button class="button back-button secondary" type="button" @click="close"><i class="fa fa-chevron-left" title="Back"></i> Back</button>
			<button class="button play-button" type="button" @click="play" title="Play / Pause"><i :class="{ 'fa': true, 'fa-pause': playing, 'fa-play': !playing }"></i></button>
			<button class="button" type="button" @click="$emit('addFavourite')" title="Add to favourites"><i class="fa fa-heart"></i></button>
		</p>
	</div>
</template>
<script>
	export default {
		props: ['modelValue'],
  		emits: ['update:modelValue', 'addFavourite'],
		data() {
			return {
				playing: false,
				loaded: false
			};
		},
		methods: {
			play() {
				var video = this.$refs.video;
				if (this.playing) {
					video.pause();
				} else {
					if (!this.loaded) {
						video.load();
						this.loaded = true;
					}
					if (video.ended) {
						video.currentTime = 0;
					}
					video.play();
				}
			},
			close() {
				this.$emit('update:modelValue', null);
			}
		},
		watch: {
			'modelValue': function() {
				this.loaded = false;
				this.playing = false;
				if (this.modelValue != null) {
					var self = this;
					self.$nextTick(function() {
						var video = self.$refs.video;
						if (!self.loaded) {
							video.load();
							self.loaded = true;
						}
					});
				}
			}
		},
		mounted() {
			var self = this;
			self.$nextTick(function() {
				var video = self.$refs.video;
				if (!self.loaded) {
					video.load();
					self.loaded = true;
				}
			});
		}
	};
</script>
<style lang="scss" scoped>
	.player {
		position: fixed;
		bottom: 0;
		right: 0;
		max-width: 75vw;

		video {
			max-width: 100%;
			max-height: 90vh;
		}
	}
	@media (min-width: 41.6875em) {
		.player {
			max-width: 30vw;

			video {
				max-width: 100%;
				max-height: 50vh;
			}
		}
	}
</style>