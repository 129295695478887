<template>
	<div class="search">
		<autocomplete @input="search" @on-select="choose" :results="results" :debounce="150" placeholder="Search..."></autocomplete>
	</div>
</template>
<script>
	require('azure-search/azure-search.min.js');
	var azureSearchClient = AzureSearch({
		url: 'https://signit-search.search.windows.net',
		key: 'F1C211816A190067FC929181B5011EAC'
	});
	import Autocomplete from 'vue3-autocomplete';
	// Optional: Import default CSS
	import 'vue3-autocomplete/dist/vue3-autocomplete.css';

	export default {
		emits: [ 'select' ],
		components: {
			autocomplete: Autocomplete
		},
		data() {
			return {
				results: []
			};
		},		
		methods: {
			search(query) {
				var self = this;
				if (query == '') {
					this.results = [];
				} else {
					azureSearchClient.search(
						"terms", {
							search: query + '*',
							count: true
						},
						(err, results, raw) => {
							self.results = results;
						});
				}
			},
			choose(selection) {
				this.$emit('select', selection);
			}
		}
	};
</script>
<style lang="scss" scoped>
	.search {
		position: relative;
	}
</style>